import { breakpoints } from "./index";

export const theme = {
    colors: {
        primary: "#086AD8",
        text: "#696969",
        heading: "#333333",
        border: "#ededed",
        borderTwo: "#f8f8f8",
        borderDash: "#dddddd",
        white: "#ffffff",
        black: "#000000",
        secondary: "#d2a98e",
        success: "#38cb89",
        info: "#17a2b8",
        warning: "#ffc107",
        danger: "#dc3545",
        light: "#f8f9fa",
        background: "#ffffff",
        gray: {
            100: "#F8F8F8",
            200: "#F6FAFE",
            300: "#d6d6d6",
            400: "#eeeeee",
            500: "#fafafa",
            600: "#f5f7fd",
            700: "#7e7e7e",
        },
        silver: "#ababab",
        silverTwo: "#cccccc",
        kimberly: "#6d70a6",
        spring: "#F6F2ED",
        pattens: "#def0ff",
        yellow: "#ffb944",
        whiteSmoke: "#f5f5f5",
        klein: "#002fa6",
        water: "#e7edfa",
    },
    lineHieghts: {
        body: 1.74,
        heading: 1.41,
    },
    fonts: {
        body: `'Inter', sans-serif`,
        heading: `'Inter', sans-serif`,
        fontAwesome: "Font Awesome 5 Free",
        fontAwesomeBrand: "Font Awesome 5 Brands",
    },
    fontSizes: {
        body: "15px",
        h1: "56px",
        h2: "48px",
        h3: "40px",
        h4: "34px",
        h5: "24px",
        h6: "18px",
    },
    fontWeights: {
        body: 400,
        heading: 700,
    },
    radii: {
        sm: "3px",
        md: "5px",
        lg: "8px",
        round: "50%",
        pill: "500px",
    },
    shadows: {
        default: "0 0 40px rgba(51, 51, 51, 0.1)",
        sm: "0 0 20px rgba(51, 51, 51, 0.1)",
        md: "0 18px 40px rgba(51, 51, 51, 0.1)",
        lg: "0 0 40px 5px rgba(51, 51, 51, 0.1)",
        xl: "0 41px 43px rgba(51, 51, 51, 0.07)",
        input: "0 0 0 0.2rem rgb(1 104 250 / 25%)",
    },
    transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
    breakpoints: [...breakpoints],
};
